import React from "react"
import Blocks from "components/blocks"
import Button from "components/button"
import Clamp from "components/clamp"
import Feature from "components/feature"
import Hero from "components/hero"
import News from "components/news"
import Text from "components/text"
import { wrapPageElement } from './../../gatsby-ssr';

export default () => {
  return (
    <>
      <Hero>
         Growth partner for category leading companies
      </Hero>
      <Clamp inset>
        <h1>Partnering with GPI Capital</h1>
        <Text type="lede">
          GPI's partners have backed category leading companies and
          deployed over $5 billion of capital operating together for over
          15 years. As an investment firm specializing in non-control growth 
          and structured equity investments, GPI seeks businesses across
          technology, consumer and industrial markets primarily in North America.
        </Text>
        <Text type="lede">
          GPI's “all-weather” strategy enables the firm to provide customized
          capital solutions to its partner companies while delivering
          attractive risk-adjusted returns across the market cycle.
        </Text>
        <h2>Our focus and impact</h2>
        <Text>
          We identify unique businesses with exceptional management teams to
          invest in, scale and achieve their potential across private
          and public stages of their lifecycle.
        </Text>
        <Text>
          As a non-control partner to founders and executives we have 
          demonstrated capabilities, data-driven insights, domain expertise
          across business models and strategies leveraging our network of 
          advisors, operators and companies to positively 
          impact businesses at scale.
        </Text>
        <Text>
          Businesses are complex, face unique challenges and those that win, do
          so with perseverance, agility and vision. We recognize the value of
          being a partner who is responsive, experienced, patient with a
          long-term perspective and willing to commit significant capital, time
          and focus to be a part of your journey.
        </Text>
      </Clamp>
      <Feature>
        <div>
          <h2>Role</h2>
          <p>
            We seek to lead and be a value add capital partner supporting
            corporate strategy, people and expansion efforts with our 
            commercial and operational resources to deliver performance 
            throughout the business cycle.
          </p>
        </div>
        <div>
          <h2>Flexible Capital Solutions</h2>
          <p>
            We provide customized capital solutions to help finance the specific
            needs of our investee companies, making investments across their
            capital structure. Our investments typically include common and
            preferred stock, convertible and other equity-like investment
            structures.
          </p>
        </div>
      </Feature>
      <Clamp inset>
        <h1 id="approach">Our approach</h1>
        <h2>Funding uses</h2>
        <Text>
          GPI Capital provides capital to its partner companies for a wide array
          of uses.
        </Text>
        <h3>Transformational capital</h3>
        <Blocks content="capital">
          <div>
            <h4>Organic initiatives</h4>
            <p>
              Funding corporate projects, geographic or product/service
              expansion, penetrating new or existing markets, platform
              extensions and growing teams
            </p>
          </div>
          <div>
            <h4>Mergers &amp; acquisitions</h4>
            <p>
              Funding acquisitions, bolt-on new capabilities or verticalize
              technology stacks and supply chains
            </p>
          </div>
          <div>
            <h4>Liquidity solutions</h4>
            <p>
              Funding governance changes, ownership transitions and
              recapitalizations
            </p>
          </div>
        </Blocks>
        <h2>Investment criteria</h2>
        <Text>
          We invest in growth-oriented companies operating at scale,
          based primarily in North America and across consumer, 
          technology and industrial sectors.
        </Text>
        <h3>Industry focus</h3>
        <Blocks content="industry">
          <div>
            <h4>Technology</h4>
            <ul>
              <li>Internet</li>
              <li>Social/marketplaces</li>
              <li>E-communications</li>
              <li>Software and services</li>
            </ul>
          </div>
          <div>
            <h4>Consumer</h4>
            <ul>
              <li>Retail/e-commerce</li>
              <li>Consumer finance</li>
              <li>Travel/leisure</li>
              <li>Services</li>
            </ul>
          </div>
          <div>
            <h4>Industrial</h4>
            <ul>
              <li>Multi-industry/services</li>
              <li>Equipment/machinery</li>
              <li>Transport/logistics</li>
            </ul>
          </div>
        </Blocks>
        <h2>Value creation approach</h2>
        <Text>
          We work closely in partnership with our portfolio companies’
          stakeholders and management to drive significant financial and
          operational improvements. We have a wealth of knowledge, best
          practices and a large supportive network to share with our portfolio
          companies. Each engagement is highly customized to the respective
          needs of the companies.
        </Text>
        <Blocks content="value">
          <div>
            <h4>Strategy</h4>
            <h5>Commercial</h5>
            <p>
              Go-to market strategies, commercial partnerships and 
              market assessment to drive rapid topline growth
            </p>
            <h5>Corporate development</h5>{" "}
            <p>M&amp;A, capital allocation and liquidity analyses</p>
          </div>
          <div>
            <h4>Operational</h4>
            <h5>Technology</h5>
            <p>Best practices and infrastructure</p>
            <h5>Talent</h5>
            <p>Organization, people and vendor search</p>
            <h5>Cost savings</h5>
            <p>Best practices to maximize profitable growth</p>
          </div>
          <div>
            <h4>Business &amp; market</h4>
            <h5>Industry landscape</h5>
            <p>
              Competitive analysis and industry insights and product disruption
              risks
            </p>
            <h5>Corporate partnerships</h5>
            <p>
              Leverage extensive and deep network to drive incremental growth
              and operational efficiencies
            </p>
          </div>
        </Blocks>
        <h2>Long term, sustainable growth - ESG</h2>
        <Text>
          GPI Capital integrates Environmental, Social and Governance (ESG)
          considerations into its diligence and analysis. We believe that an ESG
          analysis is key in the exercise of our fiduciary duty to protect our
          investors’ long-term interest by providing additional tools and lenses
          through which we can identify risks and opportunities of our
          investments.
        </Text>
      </Clamp>
      <Feature>
        <div>
          <h2>Our team</h2>
          <p>
            GPI Capital’s senior partners have been investing together across
            the same strategy for over 15+ years. GPI Capital established a core
            set of operating principles and values, “OLIFE,” that promotes a
            team-oriented culture of respect and trust bolstered by intellectual
            honesty, independent thinking, intense focus and an ownership
            mindset.
          </p>
          <p>
            {/* <Button href="/team" make="outlined">
              Meet the team
            </Button> */}
          </p>
        </div>
        <div>
          <h2>In the news</h2>
          <News quantity={1} />
          <p>
            <Button href="/news" make="outlined">
              Read more news
            </Button>
          </p>
        </div>
      </Feature>
    </>
  )
}
