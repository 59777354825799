import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { join } from "hooks/use-array"
import * as styles from "./button.module.less"

export default function Button({
  disabled,
  href,
  make,
  type,
  onClick,
  className,
  children,
}) {
  //
  //  React element
  //
  /*
  [!] Dynamically create React element
  [!] React elements are objects
  [!] JSX type must be capitalized
  */
  const Element = href && /^\/(?!\/)/.test(href) ? Link : href ? `a` : `button`

  //
  //  Props
  //
  const properties =
    href && /^\/(?!\/)/.test(href)
      ? { to: href }
      : href
      ? { href, rel: `noopener noreferrer`, target: `_blank` }
      : disabled
      ? { disabled }
      : { type }

  //
  //  Class names
  //
  const rootClasses = join(styles.root, make && styles[make], className)

  //
  //  Render
  //
  return (
    <Element className={rootClasses} {...properties} onClick={onClick}>
      <span>{children}</span>
    </Element>
  )
}

Button.defaultProps = {
  make: "outlined",
}

Button.propTypes = {
  disabled: PropTypes.bool,
  href: PropTypes.string,
  make: PropTypes.oneOf(["solid", "outlined", "text"]),
  type: PropTypes.oneOf(["button", "reset", "submit"]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}
